import { createFileRoute } from '@tanstack/react-router'

import PartnerUsersPage from '../../../../components/pages/backoffice/partners/partners-users-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/partner/$organization/users/')({
  component: () => (
    <PermissionChecker requiredPermissions={['users.read']}>
      <PartnerUsersPage />
    </PermissionChecker>
  ),
})
